@use "breakpoints";
@use "colors";
@use "mixins";
@use "typography";
@import "custom";
@import "bootstrap/scss/bootstrap";
@import "slick-carousel/slick/slick.css";
@import "animate.css/animate.min.css";

[data-debug-layout="true"] {
  *:not(path, g) {
    background: rgb(255 36 255 / 10%) !important;
    box-shadow: 0 0 0 1px rgb(255 36 255 / 100%) !important;
  }
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

body {
  color: colors.$off-black;
  inline-size: 100%;
  margin: 0;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

// https://developer.chrome.com/articles/inert/
body[inert] {
  overflow: hidden;

  [open] {
    pointer-events: initial;
  }

  .global-header {
    inset-block-start: calc(var(--global-header-height) * -1);
  }
}

body[data-has-open-modal] {
  overflow: hidden;

  @media (width >= breakpoints.$md) {
    padding-inline-end: 14px;
  }
}

html body {
  @include typography.body;
}

button {
  color: inherit;

  svg {
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;

    &[disabled],
    &[aria-disabled="true"] {
      cursor: not-allowed;
    }
  }
}

img {
  max-inline-size: 100%;
}

@media (width < breakpoints.$lg) {
  body[data-has-open-nav="true"] {
    block-size: 100vh;
    overflow: hidden;
  }
}

/**********************************************************************
 * keep tracking pixels from mucking up the layout
 */
#resetPixelContainer,
img[src^="https://d.adroll.com/ipixel/"],
img[src^="https://insight.adsrvr.org/"],
img[src^="https://secure.adnxs.com/px"],
img[src^="https://trkn.us/pixel/"]
{
  inset-block-end: 0;
  inset-inline-end: 0;
  position: absolute;
  visibility: hidden;
}

/**********************************************************************
 * Braze content cards
 */
#braze-feed {
  background-color: #eee;
  inline-size: 402px;
  inset: var(--global-header-height) auto 0 0;
  overflow: auto;
  position: fixed;
  transform: translateX(-450px);
  transition: 0.5s ease-in-out;
  z-index: 100;

  .ab-feed.ab-effect-slide {
    transform: translateX(0);
  }

  &:has(> .ab-feed) {
    inline-size: auto;
    transform: translateX(0);
  }
}

/**********************************************************************
 * Page-specific styles
 */

body[data-page="circle"] {
  // Hack to target text modules for T&C
  div[class*="module-text-inner"] {
    border: none;
    font-size: 13px;
  }

  [data-is="TiledComponent"] {
    overflow: visible;
    padding-block-start: 0;
  }

  div[class*="loader"] {
    block-size: 100vh;
  }
}

body[data-page="direct-response"] {
  [data-is="LeadFlow"] + [data-attr-module-name],
  [data-is="LeadFlow"] + [data-is] {
    padding-block-start: 10vh;
  }
}

body[data-page="dnsrequest"] {
  p {
    a {
      text-decoration: underline;
    }
  }
}

body[data-page="privacy"] {
  [data-attr-module-name="module-text"] {
    h1,
    h2 {
      margin-block: 3rem 1rem;
      text-transform: uppercase;
    }

    h3 {
      margin-block: 2rem 1rem;
    }

    table {
      border: 1px solid colors.$black;
      inline-size: 100%;
      table-layout: fixed;
    }

    th,
    td {
      border: 1px solid colors.$black;
      inline-size: 50%;
      padding: 1em;
      vertical-align: top;
    }

    a {
      overflow-wrap: anywhere;
    }

    ol,
    p,
    table,
    ul {
      margin-block: 0;

      & + ol,
      & + p,
      & + table,
      & + ul {
        margin-block-start: 2rem;
      }
    }

    li {
      ol,
      ul {
        margin-block: 0;
      }
    }
  }

  [data-attr-module-name="module-text"]
    + [data-attr-module-name="module-text"] {
    margin-block: 2rem 0;

    li + li {
      margin-block: 2rem 0;
    }
  }
}

body[data-page="wantitall"] {
  [data-attr-module-name="on-demand-single-image"] {
    @media (width < breakpoints.$md) {
      [data-is="EditorialTitle"] {
        padding-block-end: 0;
      }

      + [data-attr-module-name="on-demand-single-image"] {
        margin-block-start: 0 !important;
        padding-block-start: 0 !important;

        &::before {
          background-color: colors.$white;
          block-size: 8rem;
          content: "";
          display: block;
          mask-image: url("https://assets.cdn-equinox.com/images/equinox-symbol.svg");
          mask-position: 50% 0%;
          mask-repeat: no-repeat;
          mask-size: 2.4rem;
          opacity: 0.5;
        }
      }
    }
  }
}

.adyen-drop-in {
  .adyen-checkout {
    &__payment-method__header {
      display: none;
    }

    &__payment-method--selected {
      background-color: transparent;
      border: none;
    }

    &__payment-method__details {
      padding: 0;
    }

    &__input {
      background-clip: padding-box;
      background-color: #fff;
      block-size: calc(1.5em + 0.75rem + 2px);
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      color: #495057;
      display: block;
      font-size: 1rem;
      font-weight: 400;
      inline-size: 100%;
      line-height: 1.5;
      padding: 0.375rem 0.75rem;
      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    }

    &__label__text {
      color: #8b8b8b !important;
      display: block;
      font-size: 14px !important;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      margin: 10px 0;
      opacity: 1;
      padding: 0;
      text-align: start;
    }

    &__card__brands {
      display: none;
    }

    &__payment-method__header,
    &__fieldset__title {
      display: none;
    }

    &__error-text {
      color: red !important;
      font-size: 14px !important;
      font-weight: normal !important;
      letter-spacing: 0.14px !important;
      line-height: 24px !important;
      margin-block-start: -10px !important;
      opacity: 1;
    }

    &__card__exp-cvc {
      @media (width <= 640px) {
        display: flex;
        flex-direction: column;
      }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &__field-wrapper > .adyen-checkout__field:first-child {
      @media (width <= 640px) {
        margin-inline-end: 0;
      }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &__field-wrapper > .adyen-checkout__field:nth-child(2) {
      @media (width <= 640px) {
        margin-inline-start: 0;
      }
    }

    &__label--focused {
      &__input-wrapper {
        input {
          border: none;
          border-radius: unset;
          box-shadow: none;
          outline: none !important;
          outline-width: 0 !important;
        }
      }
    }

    &__card__cvc__hint__wrapper {
      margin-block-start: 8px !important;
      margin-inline-end: 10px !important;
    }

    &__field__exp-date_hint {
      margin-block-start: -9px !important;
    }

    &__field__exp-date_hint_wrapper--hidden {
      display: none !important;
    }
  }

  .adyen-checkout-input {
    &__inline-validation {
      margin-block-start: -3px !important;
    }
  }

  .adyen-checkout-form-instruction {
    display: none;
  }
}

.embedded-messaging {
  display: none;
}

/* stylelint-disable-next-line selector-class-pattern */
.embedded-messaging > .embeddedMessagingFrame[class~="isMaximized"] {
  block-size: 75% !important;
  inline-size: 500px !important;
}

/* stylelint-disable-next-line selector-id-pattern */
#netomiChatWindow {
  block-size: 100%;
  display: none;
  inline-size: 100vw;

  @media (width >= breakpoints.$md) {
    block-size: 75%;
    inline-size: 500px;
  }

  &.show {
    display: block;
  }
}
