@use "breakpoints";
@use "colors";

.chatbot-container {
  background-color: transparent;
  cursor: pointer;
  display: none;
  inset-block-end: 20px;
  inset-inline-end: 20px;
  position: fixed;
  z-index: 100;

  @media (width >= breakpoints.$md) {
    inset-block-end: 24px;
    inset-inline-end: 24px;
  }

  img {
    block-size: 48px;
    inline-size: 48px;
  }
}

body[data-vwo-web3617="3:34"] {
  .chatbot-container {
    display: block;
  }
}
